import React from "react";
import { useState, useRef, useLayoutEffect } from "react";
import { Layout } from "./layout";
import { graphql, Link, StaticQuery } from "gatsby";
import Enumerable from "linq";
import { formatMinutes, formatRataWithoutWeekDay, formatRataWeekDay, formatRataYear } from "../utils/rata";
import moment from 'moment';
import 'moment/locale/de-ch';
import { SearchField } from "./search";
import lunr from "lunr";
import { globalHistory } from "@reach/router";
import Axios from "axios";
import { Filter } from "./filter";
import { Image } from "./image";
import { Ribbon } from "./ribbon";
import { Kind } from "./kind";
import { imageUrlPrefix } from "../utils/config";
import { todayInRata } from "../utils/rata";
import { CookiesProvider } from 'react-cookie';
import { urlDetect } from '../utils/text';
const occurrencesStyle = require('./occurrences.module.scss');
moment.locale("de-ch");
function isEmpty(str) {
    return (!str || 0 === str.length);
}
const Occurence = ({ occurrence, winWidth }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    const linkRef = useRef();
    const [dimension, setDimension] = useState({ width: 0, height: 0 });
    useLayoutEffect(() => {
        const current = linkRef.current;
        if (current) {
            // @ts-ignore
            setDimension({ width: current.offsetWidth, height: current.offsetHeight });
        }
    }, [winWidth]);
    const picture = (_a = occurrence === null || occurrence === void 0 ? void 0 : occurrence.picture) !== null && _a !== void 0 ? _a : {};
    const width = (_b = picture.width) !== null && _b !== void 0 ? _b : 1;
    const height = (_c = picture.height) !== null && _c !== void 0 ? _c : 1;
    const centerX = (_d = picture.centerX) !== null && _d !== void 0 ? _d : 0;
    const centerY = (_e = picture.centerY) !== null && _e !== void 0 ? _e : 0;
    let pictureRatio = width / height;
    let imgRatio = dimension.width / dimension.height;
    let reduceRatio;
    let objectPosition;
    if (imgRatio > pictureRatio) {
        reduceRatio = dimension.width / width;
        let position = -((centerY * reduceRatio) - (dimension.height / 2));
        position = position > 0 ? 0 : position;
        const minPosition = -(height * reduceRatio - dimension.height - 1);
        position = position < minPosition ? minPosition : position;
        objectPosition = `0px ${position}px`;
    }
    else {
        reduceRatio = dimension.height / height;
        let position = -((centerX * reduceRatio) - (dimension.width / 2));
        position = position > 0 ? 0 : position;
        const minPosition = -(width * reduceRatio - dimension.width - 1);
        position = position < minPosition ? minPosition : position;
        objectPosition = `${position}px 0px`;
    }
    // @ts-ignore
    const image = <img ref={linkRef} alt={(_f = occurrence === null || occurrence === void 0 ? void 0 : occurrence.title) !== null && _f !== void 0 ? _f : "image"} loading="lazy" src={`${imageUrlPrefix}${(_g = occurrence === null || occurrence === void 0 ? void 0 : occurrence.parent) === null || _g === void 0 ? void 0 : _g.id}/small/${picture.id}`} style={{ objectPosition: objectPosition }}/>;
    // @ts-ignore
    const ribbon = <Ribbon status={occurrence.status}/>;
    return (<section className={occurrencesStyle.occurence} key={occurrence.id}>
            <div>
                <Link className={occurrencesStyle.occurrenceInfoBox} to={`/events/${(_h = occurrence.parent) === null || _h === void 0 ? void 0 : _h.id}`} style={{ position: "relative" }}>
                    {ribbon}
                    <h3>{occurrence.title}</h3>
                    {!isEmpty(occurrence.subTitle) && <div>{occurrence.subTitle}</div>}
                    <div>
                        {formatMinutes((_k = (_j = occurrence.time) === null || _j === void 0 ? void 0 : _j.time) !== null && _k !== void 0 ? _k : 0)} | {urlDetect((_m = (_l = occurrence.location) === null || _l === void 0 ? void 0 : _l.name) !== null && _m !== void 0 ? _m : "")}, {(_o = occurrence.location) === null || _o === void 0 ? void 0 : _o.city}
                    </div>
                </Link>
                <Link className={occurrencesStyle.occurrencePicture} to={`/events/${(_p = occurrence.parent) === null || _p === void 0 ? void 0 : _p.id}`}>
                    {image}
                </Link>
                <Kind kind={(_q = occurrence === null || occurrence === void 0 ? void 0 : occurrence.kind) !== null && _q !== void 0 ? _q : ""}/>
            </div>
        </section>);
};
function pad(n, size) {
    let num = n.toString();
    while (num.length < size)
        num = "0" + num;
    return num;
}
const Day = ({ dayRata, occurrences, isToday, winWidth, isArchive }) => {
    const id = isToday ? "today" : undefined;
    return (<section id={id} key={dayRata} className={occurrencesStyle.section + (isToday ? ` ${occurrencesStyle.today}` : '')}>
            <div>
                <h2 className={occurrencesStyle.day}>{formatRataWeekDay(dayRata)}, <span style={{ whiteSpace: 'nowrap' }}>{formatRataWithoutWeekDay(dayRata)}</span>
                    {isArchive ? " " + formatRataYear(dayRata) : ""}
                </h2>
                <div className={occurrencesStyle.occurences}>
                    {occurrences
        .orderBy(o => { var _a, _b, _c; return pad(((_b = (_a = o.time) === null || _a === void 0 ? void 0 : _a.time) !== null && _b !== void 0 ? _b : 0), 4) + ((_c = o.title) !== null && _c !== void 0 ? _c : ""); })
        .select(o => <Occurence key={o.id} occurrence={o} winWidth={winWidth}/>)}
                </div>
            </div>
        </section>);
};
const PlacedImage = ({ className, image, objectPosition }) => {
    return <div className={className}>
        <Image {...image} objectPosition={objectPosition}/>
    </div>;
};
const Miniature = ({ motiveImage, hazImage, bubbleImage, logoImage }) => {
    return <div className={occurrencesStyle.miniatures}>
        <PlacedImage className={occurrencesStyle.miniatureBallon} image={motiveImage}/>
        <PlacedImage className={occurrencesStyle.miniatureHaz} image={hazImage} objectPosition="right"/>
        <PlacedImage className={occurrencesStyle.miniatureBubble1} image={bubbleImage}/>
        <PlacedImage className={occurrencesStyle.miniatureBubble2} image={bubbleImage}/>
        <PlacedImage className={occurrencesStyle.miniatureBubble3} image={bubbleImage}/>
        <PlacedImage className={occurrencesStyle.miniatureBubble4} image={bubbleImage}/>
        <PlacedImage className={occurrencesStyle.miniatureLogoOld} image={logoImage}/>
    </div>;
};
function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
const Header = ({ searchIndex, searchTerm, setSearchTerm, selectedKind, setSelectedKind, isArchive }) => {
    const [isSticky, setIsSticky] = useState(false);
    const ref = React.createRef();
    // mount
    // useEffect(() => {
    //     const rootMargin = convertRemToPixels(2) + 1;
    //     const cachedRef = ref.current,
    //         observer = new IntersectionObserver(
    //             ([e]) => setIsSticky(e.intersectionRatio < 1),
    //             {threshold: [1], rootMargin: `-${rootMargin}px 0px 0px 0px`}
    //         );
    //     observer.observe(cachedRef as Element);
    //
    //     // unmount
    //     return function () {
    //         observer.unobserve(cachedRef as Element);
    //     }
    // }, []);
    // @ts-ignore
    const search = <header role="toolbar" ref={ref} className={occurrencesStyle.toolbarsearch + (isSticky ? " sticky withBackground" : "")}>
        <div>
            <SearchField enabled={searchIndex !== null} searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
            <Filter selectedKind={selectedKind} setSelectedKind={setSelectedKind}/>
        </div>
    </header>;
    // just for during the year.
    // const message = <header className={occurrencesStyle.toolbarinfo + (isSticky ? " sticky withBackground" : "")}>
    //     <h1>Liebes Warmer-Mai-Publikum, liebe Queer-Kultur-Interessierte!</h1>
    //
    //     <p>Wir freuen uns, euch und Ihnen auch 2023 mit dem «warmen mai» einen Monat lang Veranstaltungen von/mit/über
    //         Themen und Menschen der lesbisch-schwul-bi-trans-inter-asexuell/aromantischen Communities zu präsentieren.
    //         Ab Mitte März werden wir an dieser Stelle die Veranstaltungen bekanntgeben.
    //     </p>
    //
    //     <p>In Vorfreude auf den Mai grüsst herzlich das Team vom «warmen mai»!</p>
    //
    //     <p>PS: Kulturelle Institutionen, queere Vereine, kunstschaffende, queere Personen
    //         dürfen weiterhin ihre Veranstaltungen hier <a style={{margin: 0}} href="/register">anmelden</a>.
    //     </p>
    // </header>
    return search;
};
export const safe = (obj) => {
    return obj ? obj : {};
};
export const query = graphql `
    query EventOccurences {
        allWarmerMaiEventOccurrence {
            nodes {
                id
                parent {
                    id
                }
                picture {
                    id
                    width
                    height
                    centerX
                    centerY
                }
                location {
                    name
                    city
                }
                time {
                    day
                    time
                }
                title
                subTitle
                status
                kind
            }
        }
        motiveImage: s3StaticImages(name: {eq: "Warmermai24_FG2_1000x1000"}) {
            widths
            defaultWidth
        }
        hazImage: s3StaticImages(name: {eq: "Haz-Logo"}) {
            widths
            defaultWidth
        }
        bubbleImage: s3StaticImages(name: {eq: "warmermai24_bubble_500x500"}) {
            widths
            defaultWidth
        }
        logoImage: s3StaticImages(name: {eq: "WarmerMai_Logo24_HD"}) {
            widths
            defaultWidth
        }
    }
`;
export class OccurrencesRaw extends React.Component {
    constructor(props) {
        super(props);
        this.state = { searchIndex: null, searchTerm: "", selectedKind: null, winWidth: 0 };
        this.updateWindowsWidth = () => {
            this.setState({ winWidth: window.innerWidth });
        };
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateWindowsWidth);
        this.updateWindowsWidth();
        const origin = globalHistory.location.origin;
        Axios
            .get(`${origin}/occurrences_index.json`)
            .then(result => {
            const searchIndex = lunr.Index.load(result.data);
            this.setState({ searchIndex });
        });
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowsWidth);
    }
    render() {
        const children = this.renderOccurences();
        const motiveImage = this.props.data.motiveImage;
        const hazImage = this.props.data.hazImage;
        const bubbleImage = this.props.data.bubbleImage;
        const logoImage = this.props.data.logoImage;
        const header = <Header isArchive={this.props.isArchive} searchIndex={this.state.searchIndex} searchTerm={this.state.searchTerm} setSearchTerm={(searchTerm => {
            this.setState({ searchTerm });
        })} selectedKind={this.state.selectedKind} setSelectedKind={(selectedKind) => {
            this.setState({ selectedKind });
        }}/>;
        return <CookiesProvider>
            <Layout>
                <main id="main" role="main" className={occurrencesStyle.daysStack}>
                    {this.props.isArchive ? <div /> :
            <Miniature motiveImage={motiveImage} hazImage={hazImage} bubbleImage={bubbleImage} logoImage={logoImage}/>}
                    {header}
                    {children}
                </main>
            </Layout>
        </CookiesProvider>;
    }
    searchResultIdSet() {
        if (this.state.searchTerm !== "" && this.state.searchIndex) {
            const result = new Set();
            const searchResult = this.state.searchIndex.search(this.state.searchTerm + "*") || [];
            searchResult.forEach(sr => {
                result.add(sr.ref);
            });
            return result;
        }
        return null;
    }
    renderOccurences() {
        const idSet = this.searchResultIdSet();
        // @ts-ignore
        let nodes = this.props.data.allWarmerMaiEventOccurrence.nodes;
        return Enumerable
            .from(nodes)
            .where((node, idx) => idSet === null || idSet.has(node.id))
            .where((node) => this.state.selectedKind === null || this.state.selectedKind === node.kind)
            .where((node) => { var _a, _b; return (((_b = (_a = node.time) === null || _a === void 0 ? void 0 : _a.day) !== null && _b !== void 0 ? _b : 0) >= this.props.todayRata) || this.props.isArchive; })
            .groupBy((o) => { var _a, _b; return (_b = (_a = o.time) === null || _a === void 0 ? void 0 : _a.day) !== null && _b !== void 0 ? _b : 0; })
            .orderBy((group) => (group.key()))
            .select((group, index) => {
            let dayRata = group.key();
            return <Day dayRata={dayRata} occurrences={group} isToday={!this.props.isArchive && index === 0} isArchive={this.props.isArchive} winWidth={this.state.winWidth}/>;
        });
    }
}
export const Occurrences = (props) => {
    const todayRata = todayInRata();
    return <StaticQuery query={query} render={(data) => <OccurrencesRaw data={data} isArchive={props.isArchive} todayRata={todayRata}/>}/>;
};
