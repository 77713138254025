import React from 'react';
import { imageUrlPrefix } from "../utils/config";
export const Image = ({ defaultWidth, widths, objectPosition }) => {
    const srcSet = widths.map(([width, path]) => `${imageUrlPrefix}${path} ${width}`).join(",");
    return <picture>
        <source srcSet={srcSet} sizes="(max-width: 800px) 100vw, 800px"/>
        <img sizes="(max-width: 800px) 100vw, 800px" srcSet={srcSet} src={`${imageUrlPrefix}${defaultWidth}`} alt="" loading="lazy" style={{
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "contain",
        objectPosition: objectPosition !== null && objectPosition !== void 0 ? objectPosition : "center",
        opacity: 1,
        transition: "none"
    }}/>
    </picture>;
};
