import React from 'react';
import Select from 'react-select';
import Enumerable from "linq";
import { kinds, kindToBackgroundColor, kindToString } from "../utils/kind";
const filterStyle = require('./filter.module.scss');
const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        marginTop: "0.1rem",
        height: 10,
        width: 10,
    },
});
const colourStyles = {
    control: (styles, { isDisabled, isFocused, isSelected }) => (Object.assign(Object.assign({}, styles), { backgroundColor: 'white', minHeight: "2rem", minWidth: "20ch", borderWidth: 0, boxShadow: isFocused ? "0 0 0 2.5pt #2929cc" : "none", borderRadius: "0.75rem" })),
    valueContainer: (styles) => (Object.assign(Object.assign({}, styles), { paddingTop: 0, paddingBottom: 0 })),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return Object.assign(Object.assign({}, styles), dot(data.color));
    },
    input: (styles) => (Object.assign(Object.assign(Object.assign({}, styles), dot()), { padding: "0 0 0.1rem 0", margin: 0 })),
    placeholder: (styles) => (Object.assign(Object.assign(Object.assign({}, styles), dot()), { padding: "0 0 0.1rem 0", margin: 0 })),
    indicatorSeparator: (styles) => (Object.assign(Object.assign({}, styles), { margin: 0, width: 0 })),
    dropdownIndicator: (styles) => (Object.assign(Object.assign({}, styles), { paddingLeft: 0, paddingTop: "calc((1.5rem - 20px + 0.15rem)/2)", paddingBottom: "calc((1.5rem - 20px + 0.15rem)/2)", paddingRight: "calc((1.5rem - 20px + 0.3rem)/2)" })),
    clearIndicator: (styles) => (Object.assign(Object.assign({}, styles), { '&:hover': { color: "darkred" }, '&:active': { color: "orangered" }, color: "red", paddingLeft: 0, paddingTop: "calc((1.5rem - 20px + 0.15rem)/2)", paddingBottom: "calc((1.5rem - 20px + 0.15rem)/2)", paddingRight: 0 })),
    singleValue: (styles, { data }) => (Object.assign(Object.assign(Object.assign({}, styles), dot(data.color)), { padding: "0 0 0.1rem 0", margin: 0 })),
};
const options = Enumerable
    .from(kinds)
    .select(kindGroup => ({
    options: Enumerable
        .from(kindGroup)
        .select(kind => ({ value: kind, label: kindToString(kind), color: kindToBackgroundColor(kind) }))
        .toArray()
})).toArray();
export const Filter = ({ selectedKind, setSelectedKind }) => {
    const val = selectedKind ? { value: selectedKind, label: kindToString(selectedKind), color: kindToBackgroundColor(selectedKind) } : null;
    // @ts-ignore
    const select = <Select isClearable={true} options={options} value={val} aria-label="Nach Veranstaltungsart filtrieren" styles={colourStyles} placeholder="Veranstaltungsart..." onChange={(val, meta) => {
        if (meta.action === 'clear' || val === null) {
            setSelectedKind(null);
        }
        else {
            // @ts-ignore
            setSelectedKind(val.value);
        }
    }}/>;
    return <div className={filterStyle.filter}>
        {select}
    </div>;
};
